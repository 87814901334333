/*
* App Global CSS
* ----------------------------------------------------------------------------
* Put style rules here that you want to apply globally. These styles are for
* the entire app and not just one component. Additionally, this file can be
* used as an entry point to import other CSS/Sass files to be included in the
* output CSS.
* For more information on global stylesheets, visit the documentation:
* https://ionicframework.com/docs/layout/global-stylesheets
*/

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */

@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';

ion-icon {
	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
	}

	&.f-20 {
		font-size: 20px;
	}
}

@import './theme/output.css';

.btn-yellow,
.btn-transparent {
	margin: 0;
	border-radius: 8px;

	--padding-top: 8px;
	--padding-bottom: 8px;
	--padding-start: 8px;
	--padding-end: 8px;
	--box-shadow: none;
	--background: transparent;

	text-transform: none;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

cdk-virtual-scroll-viewport {
	height: 100%;
	width: 100%;
}

.setModal {
	&::part(content) {
		position: absolute;
		top: 56px !important;
		left: 0 !important;
		border-radius: 0;
	}
}

.btn-yellow {
	color: #000000;
	background: #ffcb2d;
}

.btn-transparent {
	border: 1px solid rgba(0, 0, 0, 0.8);
	color: #000000;
}

.logout-modal-class {
	--width: 254px;
	--box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1);
}

.notification-modal-class {
	--width: 362px;
	--height: 607px;
	--box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1);
}

.cohort-selector-modal-class {
	--width: 305px;
	--height: 156px;
	--box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1);
}

.add-group-member-modal-class {
	--width: 680px;
	--height: 480px;
	--box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1);
}

.learn-class {
	--max-height: 360px;
	--box-shadow: 0 1px 12px rgba(0, 0, 0, 0.1);
}

.ripple-parent {
	position: relative;
	overflow: hidden;
}

ion-spinner {
	vertical-align: top;
}

ion-ripple-effect {
	--ripple-color: transparent;
}

.content-box.max-w-1336 {
	padding-left: calc(calc(100vw - 1336px) / 2);
}

.content-box.max-r-1336 {
	padding-right: calc(calc(100vw - 1336px) / 2);
}

app-main-header,
app-header-non-fellow {
	.no-max-w-1336 {
		padding-left: 0 !important;
	}

	.max-w-1336 {
		max-width: 1336px;
	}
}

.ellipsis-self {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@media only screen and (max-width: 1336px) {
	app-main-header {
		.max-w-1336 {
			padding-right: 16px;
		}
	}
}

.learn-searchbar {
	padding-left: 0;
	padding-right: 0;
	padding-top: 0;
	padding-bottom: 0;

	--border-radius: 8px;
	--box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);

	input {
		padding-top: 14px !important;
		padding-bottom: 14px !important;
		padding-inline-start: 48px !important;
		font-weight: 400 !important;
		font-size: 14px !important;
		line-height: 20px !important;
	}

	ion-icon {
		font-size: 24px !important;
		width: 24px !important;
		height: 24px !important;
		top: 12px !important;
		left: 16px !important;
		color: rgba(0, 0, 0, 0.4) !important;
	}
}

.image-modal {
	padding: 32px;
	--width: 874px;
	--height: 638px;

	box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}

.certificate-view {
	--width: 100vw;
	--min-height: 80vh;
	box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}

.rating-modal {
	--width: 312px;
	--height: auto;

	box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}

.addCalendar-modal {
	--width: 225px;
	--height: auto;

	box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}

.add-to-calender {
	background: rgb(0 0 0 / 50%);
}

.modal-class-100,
.meet-request-modal {
	.ion-page {
		overflow-y: scroll;
	}
}

@media (min-width: 768px) {
	.modal-class-news {
		--max-width: 540px;
		--min-width: 540px;
		--max-height: 420px;
	}
	.modal-class-100 {
		--max-width: 540px;
		--min-width: 540px;
	}
}

@media (min-width: 916px) {
	.meet-request-modal {
		--max-width: 876px;
		--min-width: 876px;
	}
}
@media (min-width: 1281px) {
	.certificate-view {
		--width: 874px;
		--height: 638px;

		box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
	}
}

@media (max-width: 575.98px) {
	.add-to-calender {
		.popover-content {
			left: 0 !important;
			right: 0 !important;
			width: 100% !important;
			bottom: 0 !important;
		}
	}
}

.my-success-msg {
	background: rgb(0 0 0 / 50%);

	.popover-content {
		width: 428px;
		background: #f0f6ff;
		border: 1px solid #adc8ec;
		box-sizing: border-box;
		border-radius: 4px;
	}
}

@media (max-width: 480px) {
	.my-success-msg {
		.popover-content {
			background: #f0f6ff;
			border: 1px solid #adc8ec;
			box-sizing: border-box;
			border-radius: 4px;
			width: calc(100vw - 40px);
		}
	}
}

@media (min-width: 820px) {
	.manage-calendar-event {
		.modal-wrapper {
			height: 85vh;
			width: 879px;
			border-radius: 8px;
		}
	}
}

ion-modal {
	app-meet-request {
		overflow-y: scroll !important;
	}
}

ion-popover.learn-class {
	z-index: 50000;
}

ion-popover {
	&.my-success-msg {
		--width: 500px;
	}
}
ion-popover {
	&.grow-locked {
		--width: 421px;
	}
}

ion-popover {
	&.feedback-msg {
		--width: 428px;
	}
}
ion-popover {
	&.feedback-msg-no {
		--width: 582px;
	}
}

@media (max-width: 767px) {
	ion-popover {
		&.feedback-msg-no {
			--width: 328px;
		}
	}
	ion-popover {
		&.feedback-msg {
			--width: 328px;
		}
	}
}

@media (max-width: 368px) {
	ion-popover {
		&.feedback-msg-no {
			--width: calc(100% - 40px);
		}
	}
	ion-popover {
		&.feedback-msg {
			--width: calc(100% - 40px);
		}
	}
}
@media (max-width: 500px) {
	ion-popover {
		&.grow-locked {
			--width: calc(100% - 20px);
		}
	}
}
.w-320px {
	width: 320px;
}

.Blacks_Light_Black_1 {
	--backdrop-opacity: 0.8;
	--border-radius: 8px;
}

.confirm-box {
	--max-height: 197px;
	--min-height: 197px;
	--max-width: 652px;
	--min-width: 652px;

	background: rgb(0 0 0 / 77%);

	--border-radius: 8px;
}

@media (max-width: 651.98px) {
	.confirm-box {
		--max-height: 240px;
		--min-height: 240px;
		--max-width: 317px;
		--min-width: 317px;
	}
}

.tox-notification {
	position: fixed !important;
	top: 29px !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
}

.password-modal-class {
	--width: 428px;

	--box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1);
	--border-radius: 8px;
}

.main-page {
	.sidemenu {
		padding-top: 32px;
		flex: 0 0 calc(calc(2 / var(--ion-grid-columns, 12)) * 100%);
		width: calc(calc(2 / var(--ion-grid-columns, 12)) * 100%);
		max-width: 230px;
	}

	.main-content {
		.cdk-virtual-scroll-content-wrapper {
			padding-right: calc(calc(100vw - 1336px) / 2);

			> * > ion-grid {
				padding-left: calc(100% / 10);
				padding-right: calc(100% / 10);

				&.none {
					padding-left: 0;
					padding-right: 0;
				}
			}
		}
	}
}

.width-262 {
	width: 262px;
	max-width: 262px;
	min-width: 262px;
}

@media (min-width: 769px) {
	.ion-hide-md-up {
		display: none !important;
	}
}

@media (max-width: 768px) {
	.ion-hide-md-down {
		display: none !important;
	}
}

@media (min-width: 993px) {
	.ion-hide-lg-up {
		display: none !important;
	}
}

@media (max-width: 992px) {
	.ion-hide-lg-down {
		display: none !important;
	}

	body[class^='_app_events'],
	body[class^='_app_learning_pathways_'],
	body[class^='_app_learning_module_'],
	._app_learning,
	._app_work,
	._app_profile,
	._app_profile_mentors,
	._app_profile_open-door,
	._app_profile_terra-fellows,
	._app_profile_people-directory {
		.intercom-lightweight-app-launcher,
		.intercom-namespace .intercom-dfosxs {
			bottom: calc(76px + 20px) !important;
		}
	}

	._app_profile_view_me {
		.intercom-lightweight-app-launcher,
		.intercom-namespace .intercom-dfosxs {
			bottom: 20px !important;
		}
	}

	body[class^='_app_profile_view_'] {
		app-community-header {
			display: none;
		}
	}
}
@media (max-width: 991px) {
	._app_learning {
		.intercom-lightweight-app-launcher,
		.intercom-namespace .intercom-dfosxs {
			display: none;
		}
	}
}
@media (min-width: 992px) {
	._app_learning {
		.intercom-lightweight-app-launcher,
		.intercom-namespace .intercom-dfosxs,
		.intercom-namespace .intercom-x98k24 {
			left: 16px;
		}
	}
}
ion-item-divider {
	--inner-padding-end: 0;
}

.popoverRadius::part(content) {
	border-radius: 8px;
}

.mb-m-64 {
	margin-bottom: 32px;
}

@media screen and (max-width: 992px) {
	.mb-m-64 {
		margin-bottom: 64px;
	}

	.plt-android {
		.mb-m-64 {
			margin-bottom: 120px;
		}
	}

	.plt-ios {
		.mb-m-64 {
			margin-bottom: 158px;
		}
	}
}

.info-card {
	max-width: 355px;
	margin: auto;
	padding-top: 0;
	padding-bottom: 5%;
}

youtube-player iframe {
	width: 100%;
	height: 504px;
}

@media screen and (max-width: 768px) {
	youtube-player iframe {
		height: 380px;
	}
}

@media screen and (max-width: 992px) {
	.info-card {
		max-width: 355px;
		margin: auto;
		padding-top: 10%;
		padding-bottom: 20%;
	}
}

@media screen and (min-width: 993px) {
	.pt-992-32 {
		padding-top: 32px;
	}
}

.event_type {
	// Terra Event
	&_1 {
		background: linear-gradient(
				0deg,
				rgba(255, 255, 255, 0.65),
				rgba(255, 255, 255, 0.65)
			),
			#ffcb2d;
	}
	// Unknown
	&_2 {
		background: linear-gradient(
				0deg,
				rgba(255, 255, 255, 0.85),
				rgba(255, 255, 255, 0.85)
			),
			#dd0000;
	}
	// Community Event
	&_3 {
		background: linear-gradient(
				0deg,
				rgba(255, 255, 255, 0.8),
				rgba(255, 255, 255, 0.8)
			),
			#5566fb;
	}
	// Job Fair
	&_4 {
		background: linear-gradient(
				0deg,
				rgba(255, 255, 255, 0.77),
				rgba(255, 255, 255, 0.77)
			),
			#00daae;
	}
	// Discussion
	&_5 {
		background: linear-gradient(
				0deg,
				rgba(255, 255, 255, 0.85),
				rgba(255, 255, 255, 0.85)
			),
			#dd0000;
	}
	// Course Info
	&_6 {
		background: linear-gradient(
				0deg,
				rgba(255, 255, 255, 0.65),
				rgba(255, 255, 255, 0.65)
			),
			#ffcb2d;
	}
	// Networking
	&_7 {
		background: linear-gradient(
				0deg,
				rgba(255, 255, 255, 0.8),
				rgba(255, 255, 255, 0.8)
			),
			#5566fb;
	}
	// Career Building
	&_8 {
		background: linear-gradient(
				0deg,
				rgba(255, 255, 255, 0.77),
				rgba(255, 255, 255, 0.77)
			),
			#00daae;
	}
	// Paid Events
	&_9 {
		background: linear-gradient(
				0deg,
				rgba(255, 255, 255, 0.85),
				rgba(255, 255, 255, 0.85)
			),
			#dd0000;
	}
}

@media screen and (min-width: 993px) {
	.notInstall1 {
		width: calc(100% - 262px) !important;
		max-width: calc(100% - 262px) !important;
		min-width: calc(100% - 262px) !important;
		padding-left: 20px;
		padding-right: 20px;
		// padding-right: calc((100vw - 1336px) / 6);
		// padding-left: calc((100vw - 1336px) / 6);
	}
}

.slider-library {
	--bullet-background: #aab2fd;
	--bullet-background-active: var(
		--Blues-Dark-Blue-2,
		linear-gradient(0deg, rgba(0, 0, 0, 0.37) 0%, rgba(0, 0, 0, 0.37) 100%),
		#5566fb
	);

	.swiper-pagination-bullet {
		width: 16px;
		height: 16px;
	}
}

ion-modal > .ion-page {
	position: relative;
	contain: layout style;
	height: 100%;
}
