.collapse {
  visibility: collapse
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: sticky
}

.order-1 {
  order: 1
}

.order-11 {
  order: 11
}

.order-2 {
  order: 2
}

.order-3 {
  order: 3
}

.float-right {
  float: right
}

.float-left {
  float: left
}

.m-16 {
  margin: 16px
}

.m-8 {
  margin: 8px
}

.m-auto {
  margin: auto
}

.mx-16 {
  margin-left: 16px;
  margin-right: 16px
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px
}

.mx-24 {
  margin-left: 24px;
  margin-right: 24px
}

.mx-4 {
  margin-left: 4px;
  margin-right: 4px
}

.mx-58 {
  margin-left: 58px;
  margin-right: 58px
}

.my-12 {
  margin-top: 12px;
  margin-bottom: 12px
}

.my-16 {
  margin-top: 16px;
  margin-bottom: 16px
}

.my-24 {
  margin-top: 24px;
  margin-bottom: 24px
}

.my-28 {
  margin-top: 28px;
  margin-bottom: 28px
}

.my-32 {
  margin-top: 32px;
  margin-bottom: 32px
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto
}

.mb-12 {
  margin-bottom: 12px
}

.mb-16 {
  margin-bottom: 16px
}

.mb-24 {
  margin-bottom: 24px
}

.mb-32 {
  margin-bottom: 32px
}

.mb-4 {
  margin-bottom: 4px
}

.mb-8 {
  margin-bottom: 8px
}

.ml-12 {
  margin-left: 12px
}

.ml-16 {
  margin-left: 16px
}

.ml-32 {
  margin-left: 32px
}

.ml-4 {
  margin-left: 4px
}

.ml-8 {
  margin-left: 8px
}

.ml-auto {
  margin-left: auto
}

.mr-12 {
  margin-right: 12px
}

.mr-16 {
  margin-right: 16px
}

.mr-24 {
  margin-right: 24px
}

.mr-32 {
  margin-right: 32px
}

.mr-4 {
  margin-right: 4px
}

.mr-8 {
  margin-right: 8px
}

.mt-12 {
  margin-top: 12px
}

.mt-16 {
  margin-top: 16px
}

.mt-2 {
  margin-top: 2px
}

.mt-20 {
  margin-top: 20px
}

.mt-24 {
  margin-top: 24px
}

.mt-32 {
  margin-top: 32px
}

.mt-4 {
  margin-top: 4px
}

.mt-48 {
  margin-top: 48px
}

.mt-8 {
  margin-top: 8px
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3
}

.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4
}

.block {
  display: block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.contents {
  display: contents
}

.hidden {
  display: none
}

.size-12 {
  width: 12px;
  height: 12px
}

.h-16 {
  height: 16px
}

.h-20 {
  height: 20px
}

.h-24 {
  height: 24px
}

.h-48 {
  height: 48px
}

.h-50 {
  height: 50px
}

.h-56 {
  height: 56px
}

.min-h-48 {
  min-height: 48px
}

.w-16 {
  width: 16px
}

.w-20 {
  width: 20px
}

.w-24 {
  width: 24px
}

.w-56 {
  width: 56px
}

.w-58 {
  width: 58px
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content
}

.w-full {
  width: 100%
}

.min-w-fit {
  min-width: -moz-fit-content;
  min-width: fit-content
}

.flex-1 {
  flex: 1 1 0%
}

.flex-grow {
  flex-grow: 1
}

.grow {
  flex-grow: 1
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cursor-pointer {
  cursor: pointer
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.flex-nowrap {
  flex-wrap: nowrap
}

.items-center {
  align-items: center
}

.items-stretch {
  align-items: stretch
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.gap-1 {
  gap: 1px
}

.gap-16 {
  gap: 16px
}

.gap-2 {
  gap: 2px
}

.gap-20 {
  gap: 20px
}

.gap-32 {
  gap: 32px
}

.gap-4 {
  gap: 4px
}

.gap-8 {
  gap: 8px
}

.gap-x-16 {
  column-gap: 16px
}

.gap-x-8 {
  column-gap: 8px
}

.gap-y-16 {
  row-gap: 16px
}

.gap-y-32 {
  row-gap: 32px
}

.gap-y-8 {
  row-gap: 8px
}

.self-start {
  align-self: flex-start
}

.self-center {
  align-self: center
}

.overflow-y-scroll {
  overflow-y: scroll
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.text-wrap {
  text-wrap: wrap
}

.rounded-\[8px\] {
  border-radius: 8px
}

.rounded-full {
  border-radius: 9999px
}

.rounded-lg {
  border-radius: 0.5rem
}

.rounded-md {
  border-radius: 0.375rem
}

.rounded-b-lg {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem
}

.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem
}

.border {
  border-width: 1px
}

.border-r-8 {
  border-right-width: 8px
}

.bg-transparent {
  background-color: transparent
}

.bg-violet-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 233 254 / var(--tw-bg-opacity))
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.p-12 {
  padding: 12px
}

.p-16 {
  padding: 16px
}

.p-20 {
  padding: 20px
}

.p-32 {
  padding: 32px
}

.p-4 {
  padding: 4px
}

.p-74 {
  padding: 74px
}

.p-8 {
  padding: 8px
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px
}

.px-24 {
  padding-left: 24px;
  padding-right: 24px
}

.px-28 {
  padding-left: 28px;
  padding-right: 28px
}

.px-8 {
  padding-left: 8px;
  padding-right: 8px
}

.py-12 {
  padding-top: 12px;
  padding-bottom: 12px
}

.py-16 {
  padding-top: 16px;
  padding-bottom: 16px
}

.py-2 {
  padding-top: 2px;
  padding-bottom: 2px
}

.py-32 {
  padding-top: 32px;
  padding-bottom: 32px
}

.py-8 {
  padding-top: 8px;
  padding-bottom: 8px
}

.pb-12 {
  padding-bottom: 12px
}

.pb-16 {
  padding-bottom: 16px
}

.pb-20 {
  padding-bottom: 20px
}

.pb-24 {
  padding-bottom: 24px
}

.pb-32 {
  padding-bottom: 32px
}

.pb-4 {
  padding-bottom: 4px
}

.pb-8 {
  padding-bottom: 8px
}

.pl-12 {
  padding-left: 12px
}

.pl-16 {
  padding-left: 16px
}

.pl-24 {
  padding-left: 24px
}

.pl-32 {
  padding-left: 32px
}

.pl-33 {
  padding-left: 33px
}

.pl-8 {
  padding-left: 8px
}

.pr-1 {
  padding-right: 1px
}

.pr-16 {
  padding-right: 16px
}

.pr-32 {
  padding-right: 32px
}

.pr-4 {
  padding-right: 4px
}

.pr-8 {
  padding-right: 8px
}

.pt-16 {
  padding-top: 16px
}

.pt-2 {
  padding-top: 2px
}

.pt-24 {
  padding-top: 24px
}

.pt-32 {
  padding-top: 32px
}

.pt-4 {
  padding-top: 4px
}

.pt-8 {
  padding-top: 8px
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-end {
  text-align: end
}

.align-middle {
  vertical-align: middle
}

.align-text-bottom {
  vertical-align: text-bottom
}

.font-sans {
  font-family: Libre Franklin, sans-serif
}

.font-serif {
  font-family: Libre Franklin, serif
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.font-bold {
  font-weight: 700
}

.font-medium {
  font-weight: 500
}

.font-normal {
  font-weight: 400
}

.font-semibold {
  font-weight: 600
}

.uppercase {
  text-transform: uppercase
}

.lowercase {
  text-transform: lowercase
}

.capitalize {
  text-transform: capitalize
}

.normal-case {
  text-transform: none
}

.italic {
  font-style: italic
}

.leading-6 {
  line-height: 1.5rem
}

.text-Blues_Default_Blue {
  --tw-text-opacity: 1;
  color: rgb(85 102 251 / var(--tw-text-opacity))
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity))
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity))
}

.text-rose-600 {
  --tw-text-opacity: 1;
  color: rgb(225 29 72 / var(--tw-text-opacity))
}

.text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity))
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.text-zinc-500 {
  --tw-text-opacity: 1;
  color: rgb(113 113 122 / var(--tw-text-opacity))
}

.underline {
  text-decoration-line: underline
}

.no-underline {
  text-decoration-line: none
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}
